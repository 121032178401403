import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { type ReactNode } from "react";

import cn from "../../util/cn.js";

interface Props {
  className?: string;
  children: ReactNode;
  id?: string;
  icon?: ReactNode;
}

const LabelStyles = cva("", {
  variants: {
    intent: {
      primary: "text-primary dark:text-white font-medium text-base",
      secondary: "text-sm text-secondary dark:text-white/80 font-medium",
      tertiary: "text-xs text-tertiary dark:text-white/80 font-medium",
    },
    maxLines: {
      1: "line-clamp-1",
      2: "line-clamp-2",
      3: "line-clamp-3",
      4: "line-clamp-4",
    },
  },
  defaultVariants: {
    intent: "primary",
    maxLines: 1,
  },
});

const Label = ({
  className,
  children,
  id,
  icon,
  ...styleProps
}: Props & VariantProps<typeof LabelStyles>) => {
  if (icon) {
    return (
      <div id={id} className={cn("flex items-center gap-2", className)}>
        {icon && <span className="text-tertiary">{icon}</span>}
        <div className={LabelStyles(styleProps)}>{children}</div>
      </div>
    );
  } else {
    return <div className={cn(LabelStyles(styleProps), className)}>{children}</div>;
  }
};

export default Label;
